import { logger as globalLogger } from '@tickrr/lib/logger';

const logger = globalLogger.child(
	{ module: 'webapp' },
	{
		msgPrefix: '[webapp] '
	}
);

export { logger };
